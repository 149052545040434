<template>
  <div class="task-detail">
    <div class="step-1">
      <div class="step-name">步骤·1</div>
      <div class="copy-card">
        <div class="title">
          请复制下方「文案」，用于分享给好友或发朋友圈；
        </div>
        <div class="content">{{ taskDetail.description || "暂无文案" }}</div>
        <div class="btn-box" v-if="taskDetail.description">
          <van-button type="info" class="btn" @click="copyText">一键复制文案</van-button>
        </div>
      </div>
    </div>
    <div class="step-2">
      <div class="step-name">步骤·2</div>
      <div class="poster-card">
        <div class="title">点击下方图片，长按保存到手机；</div>
        <div class="content">
          <div class="share-box">
            <div class="share-image-box" @click="previewImage(taskDetail.attachOssUrl)">
              <img class="share-image" :src="taskDetail.attachOssUrl" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="step-3">
      <div class="step-name">步骤·2</div>
      <div class="url-card">
        <div class="title">
          点击下方链接，进入内容详情页，直接进行分享；
        </div>
        <div class="content" @click="jumpUrl(taskDetail.cmeVo.previewUrl)">
          <img class="junmp-url" :src="taskDetail.cmeVo.imgUrl" alt="" />
          <div class="jump-content">
            <div class="text van-multi-ellipsis--l2">
              {{ taskDetail.cmeVo.guideAbstract }}
            </div>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icona-1212jiantou"></use>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import taskDetail from '@/api/spreadTaskDetail';
import { Toast } from 'vant';
export default {
  data() {
    return {
      taskDetail: {
        cmeVo: {
          cmeUrl: '',
          imgUrl: '',
          guideAbstract: '',
        },
      },
    };
  },
  methods: {
    copyText() {
      //如果需要回调：
      this.$copyText(this.taskDetail.description).then(
        () => {
          Toast('复制成功');
        },
        function() {
          Toast('复制失败');
        }
      );
    },
    //预览图片
    previewImage(image) {
      console.log(image, '详情触发图片预览');
      this.$wx.previewImage({
        current: image, // 当前显示图片的http链接
        urls: [image], // 需要预览的图片http链接列表
      });
    },
    //获取传播任务详情
    async getTaskDetail() {
      let taskId = this.$route.query.taskId;
      let estateId = this.$route.query.estateId;
      let { code, data } = await taskDetail.getSpreadDetail({ taskId, estateId });
      if (code === '0') {
        this.taskDetail = data;
      }
    },
    jumpUrl() {
      this.$router.push(`/consultant/taskDetail?taskId=${this.$route.query.taskId}`);
    },
  },
  mounted() {
    this.getTaskDetail();
  },
};
</script>

<style lang="less" scoped>
.task-detail {
  padding: 0px 12px;
  overflow: hidden;
  .step-1,
  .step-2,
  .step-3 {
    margin-top: 24px;
    background: url("../../assets/images/task-detail.png") no-repeat;
    background-position: left top;
    background-size: 24px;
    .step-name {
      font-size: 17px;
      font-weight: bold;
      color: @gray3;
      line-height: 24px;
      letter-spacing: 1px;
    }
  }
  .copy-card,
  .poster-card,
  .url-card {
    margin-top: 16px;
    background: #ffffff;
    border-radius: 4px;
    .content {
      padding: 16px 12px;
    }
    .title {
      padding: 14px 12px;
      font-size: 12px;
      font-weight: bold;
      color: @gray3;
      line-height: 17px;
      border-bottom: 1px solid #f6f6f6;
    }
  }
  .step-1 {
    .copy-card {
      .btn-box {
        padding: 8px 12px 16px 12px;
        display: flex;
        justify-content: flex-end;
        .btn {
          padding: 8px 12px;
          background: #f0f4fe;
          border-radius: 4px;
          font-size: 12px !important;
          color: @blue;
          line-height: 17px;
        }
      }
    }
  }
  .step-2 {
    .step-name {
      position: relative;
      &::before {
        position: absolute;
        left: 61px;
        bottom: 5px;
        content: "针对新客户";
        font-size: 10px;
        color: #ffffff;
        line-height: 14px;
        padding: 1px 7px;
        background: #3a74f2;
        border-radius: 0px 4px 0px 4px;
      }
    }
    .share-box {
      width: 160px;
      height: 160px;
      position: relative;
      &::before {
        position: absolute;
        width: 110%;
        height: 22px;
        content: "";
        left: -5%;
        bottom: -11px;
        background: url("../../assets/images/share-mask.png") no-repeat center;
        background-size: 100%;
        z-index: 11;
      }
      &::after {
        position: absolute;
        width: 110%;
        height: 11px;
        content: "";
        left: -5%;
        bottom: -11px;
        z-index: 22;
        background-color: #ffffff;
      }
      .share-image-box {
        width: 160px;
        height: 160px;
        overflow: hidden;
        .share-image {
          width: 160px;
        }
      }
    }
  }
  .step-3 {
    .step-name {
      position: relative;
      &::before {
        position: absolute;
        left: 61px;
        bottom: 5px;
        content: "针对老客户";
        font-size: 10px;
        color: #ffffff;
        line-height: 14px;
        padding: 1px 7px;
        background: #3a74f2;
        border-radius: 0px 4px 0px 4px;
      }
    }
    .content {
      display: flex;
      .jump-content {
        background: rgba(48, 49, 51, 0.04);
        padding: 8px 8px 8px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .text {
          width: 216px;
          font-size: 12px;
          color: @gray6;
          line-height: 17px;
          margin-right: 12px;
        }
        .icon {
          width: 12px;
          height: 24px;
        }
      }
      .junmp-url {
        width: 67px;
        height: 50px;
      }
    }
  }
}
</style>
